/**
 * Общий принцип:
 * 1. разбиваем тектовые ноды
 * 2. разбиваем обычные ноды
 * В селекте получаются только полные ноды
 * 3. дальше проходимся по ним
 *  - там где есть <span> добавляем новый класс
 *  - там где нет, оборачиваем <span>'ом
 *
 * Оформление меняется добавлением/удалением классов.
 */
var OK = OK || {};
OK.editor = {
    CLASS: 'ok-e',
    JS_CLASS: 'js-ok-e',
    /*
     Новые версии вебкита стали поддерживать расширенный text-decoration. Стиль они читают как "text-decoration: underline solid red", а установить пока так не могут.
     Поэтому будем отрезать лишнее у значения text-decoration, оставляя только underline или line-through.
     http://www.w3.org/TR/css-text-decor-3/
     */
    DECORATION_STYLE_NAME: 'text-decoration'
};

OK.editor.el = { // DOM-элемент, являющийся текстареей

    /*
     bridge необходим для связки js-методов с JSNI-методами каждого из экземпляров TextareaLight
     После инициализации ереопределим bridge в методе TextareaLight.init();
     */
    bridge: {
        triggerSave: {},
        send: {},
        updateLengthCounter: {},
        closeDropdowns: {},
        onFocus: {},
        onArrowUpClickOnEmptyTA: {},
        onEscClick: {},
        openTextarea: {}
    }
};

var OKdiscussions; //TODO: переделать на поля объекта OK.editor?

OK.editor.caretPosition; // поле для сохранения позиции каретки

OK.editor.hasFocus = false; // есть ли фокус у текстареи

/* Методы, которые навешиваются на элемент редактора */
OK.editor.harness = {

    timer: 0,

    onKeyDown: function (e, sendOnCtrlEnter) {
        var editor = OK.editor,
            el = editor.el,
            util = editor.util,
            tools = editor.tools,
            bridge = el.bridge,
            ctrlKey;

        // Нажали enter
        if (e.keyCode == 13 && !e.altKey) {
            ctrlKey = e.ctrlKey || e.metaKey; // metaKey == command на маке (детектится только на keyDown)

            // WEBKIT игнорирует shift+enter
            if (e.shiftKey) {
                if (util.isWebkit) {
                    tools.insertHtml("br");
                }
            } else if (
                (sendOnCtrlEnter && ctrlKey) // Остались пользователи, которые посылают по ctrl+enter
                || (!sendOnCtrlEnter && !ctrlKey)) { // А эти пользователи посылают по enter

                bridge.triggerSave();

                if (el.data('emojiarea')) {
                    el.data('emojiarea').hideMenu();
                }

                OK.logging.logger.success("ta", "send", ctrlKey ? "ctrlEnter" : "enter"); // логируем по какому сочетанию клавиш отослали

                e.preventDefault();
            }
        } else if (e.keyCode == 38 && !(e.ctrlKey || e.metaKey || e.altKey)) {
            if (util.getAreaText().length == 0) {
                bridge.onArrowUpClickOnEmptyTA();
                e.preventDefault();
            }
        } else if (e.keyCode == 27 && !(e.ctrlKey || e.metaKey || e.altKey)) {
            bridge.onEscClick(e);
        }

        clearTimeout(this.timer); // прибиваем предыдущий, не успевший сработать таймер

        this.timer = setTimeout(
            function () {
                bridge.closeDropdowns();
                bridge.updateLengthCounter('keyDown');
                util.updateSrollStyles();
            }, 100
        );
    },

    click: function () {
        var editor = OK.editor,
            selector = editor.selector;

        if (selector.supportsSelection()) {
            editor.util.saveCaretPosition();
            setTimeout('OK.editor.tools.highlightFormatting()', 50);
        }
    }
};


/* Selector */
OK.editor.selector = {

    getElement: function (element) { return util.isText(element) ? element.parentNode : element; },

    addRange: function (range) {
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    },

    /** Получаем ноду фокуса, при тексте, картинке или <br/> спускаемся к родителю */
    getFocusElement: function (range) {
        var startContainer;
        if (!range) return null;
        startContainer = util.getSelectedNode(range, range.startContainer, range.startOffset, true);
        if (util.isText(startContainer) || util.isImg(startContainer) || util.isElement(startContainer, 'BR')) {
            return startContainer.parentNode;
        }
        return startContainer;
    },

    getFocusNode: function () { return window.getSelection().rangeCount > 0 ? window.getSelection().getRangeAt(0).startContainer : null; },

    getFocusOffset: function () { return window.getSelection().getRangeAt(0).startOffset; },

    /** Считаем рабочим селектом при выбранном одном диапазоне (или просто фокусе) и если находимся в редакторе */
    isSelected: function (selection) { return selection.rangeCount == 1 && selection.getRangeAt(0)
        && util.isInEditor(selection.getRangeAt(0).startContainer); },

    supportsSelection: function () { //  браузер поддерживает объект Selection
        return (typeof window.getSelection != "undefined");
    },

    supportsRange: function () { // браузер поддерживает объект Range
        return (this.supportsSelection() && typeof document.createRange != "undefined");
    },

    supportsTextRange: function () { // Поддерживает аналог объекта Range в IE <= 8.  (http://msdn.microsoft.com/en-us/library/ms535872(v=vs.85).aspx)
        return (typeof document.body.createTextRange != "undefined");
    },

    getSelection: function () {
        if (this.supportsSelection()) {
            return window.getSelection();
        }
        return null;
    },

    getRange: function ()  {
        try {
            var selection = this.getSelection();
            if (selection && this.isSelected(selection)) {
                return selection.getRangeAt(0);
            }
            return null;
        } catch (e) {
            OK.logging.logger.error("ta.e", "getRange_e");
            return null;
        }
    },

    createTextRange: function ()  { // IE <= 8
        var textRange, selection = document.selection
        if (selection != null) {
            textRange = selection.createRange();
            return textRange;
        }
        return null;
    },

    setStart: function (range, textNode) { range.setStart(textNode, 0); },

    /** Проставляем конец диапазона в зависимости от браузера */
    setEnd: function (range, node) {
        var endOffset;
        if (util.isText(node)) {
            range.setEnd(node, node.nodeValue.length); // если тектовая нода, просто сдвигаем на конец
        } else {
            if (util.isWebKit) {
                range.setEnd(util.normalizeFocus(node), 0); // выставляем на следующую тектовую ноду-заглушку
            } else {
                endOffset = Array.prototype.indexOf.call(node.parentNode.childNodes, node); // проставляем ноду по позиции в контейнере
                range.setEnd(node.parentNode, endOffset);
            }
        }
    }
};

/**
 * Панель форматирования, может меняться в зависимости от тулбара редатора.
 */
OK.editor.tools = {

    /**
     * Вставка картинки через нативный {@see Range.insertNode}, добавление стиля картинки и невозможности ее селекта.
     * Тут же добавление пробела и сдвиг фокуса после него.
     */
    insertHtml: function (originHtml) {
        var selector = OK.editor.selector,
            util = OK.editor.util,
            nbsp = String.fromCharCode(160), // Non-breaking space. Обычный пробел не подходит, т.к. некоторые браузера его «схлопывают»
            range, textRange, selection, startContainer, endContainer, startContainerParent, node, isImg, params, htmlToPaste;


        // получили текст или уже DOM-элемент?
        if (typeof originHtml.nodeType == "undefined") {
            // из текста создаём DOM-элемент
            node = document.createElement(originHtml);
        } else {
            // DOM-элемент, значит, чтобы источник не перенёсся, а скопировался, нужно склонировать
            node = originHtml.cloneNode(false);

            if (util.isImg(node)) {
                isImg = true;
            }
        }


        if (selector.supportsRange()) {
            selection = selector.getSelection();
            range = selector.getRange();
            if (selection && range) {
                range.deleteContents(); // удаляем если был выделен какой-то текст

                // если каретка стоит в середине спана, то чтобы смайлик не попал в оформление, нам нужно этот спан разбить на 2 новых спана и вставить смайл посередине
                if (util.isSpan(selector.getElement(selection.anchorNode))) {
                    startContainer = util.getSelectedNode(range, range.startContainer, range.startOffset, true);
                    endContainer = util.getSelectedNode(range, range.endContainer, range.endOffset, false);

                    OK.editor.command.splitTextNodes(range, startContainer, endContainer);
                    OK.editor.command.splitSpans(range, startContainer, endContainer);

                    startContainerParent = startContainer.parentNode;
                    // удаляем пустой span-предок, от которого наследовали и вынесли новые спаны
                    if (startContainerParent === endContainer.parentNode && util.isEmpty(startContainerParent)) {
                        $(startContainerParent).remove();
                    }
                }

                range.insertNode(node);
                if (isImg) {
                    params = { text: nbsp, offset: 1 }; // сдвинем с пробелом
                }
                util.moveFocus(node, params);

                tools.highlightFormatting();
            }

        } else if (selector.supportsTextRange()){ // IE <= 8
            textRange = selector.createTextRange();
            if (textRange) {
                htmlToPaste = node.outerHTML;
                if (isImg) {
                    htmlToPaste += nbsp; // добавим пробел после смайлика
                }
                textRange.pasteHTML(htmlToPaste);
            }
        }
    },

    /**
     * Обратная подсветка форматирования при попадании на форматированный текст.
     * В случае диапазона подсвечивает все виды форматирования с возможностью отщелкнуть.
     * @param range
     */
    highlightFormatting: function () {
        var focusElement, spans, decoration, styles, x, range,
            selector = OK.editor.selector,
            step = 0;

        try {
            if (selector.supportsRange() && typeof selector.getRange != "undefined") {
                step = 1;
                range = selector.getRange();
                step = 2;
                decoration = $('.decoration');

                if (range) {
                    step = 3;
                    tools.cleanFormatting(); // очищаем когда ничего не выбрано
                    step = 4;
                    if (range.collapsed) {
                        step = 5;
                        focusElement = selector.getFocusElement(range);
                        step = 6;
                        if (focusElement && focusElement.style.cssText) {
                            step = 7;
                            tools.cleanFormatting();
                            step = 8;
                            $.each(focusElement.style.cssText.split(";"), function (i, cssStyle) {
                                if (cssStyle != "") {
                                    step = 9;
                                    tools.decorate(decoration, cssStyle);
                                }
                            });
                        }
                    } else {
                        styles = {};
                        step = 10;
                        spans = range.cloneContents().querySelectorAll('span'); // TODO может можно как-то и без клонирования, облегчить так сказать
                        step = 11;

                        $.each(spans.length == 0 ? [selector.getFocusElement(range)] : spans, function (i, span) { // если нет <span> в клоне - значит только текстовые ноды, берем первый <span>
                            step = 12;
                            if (!span.style.cssText) return;
                            step = 13;
                            $.each(span.style.cssText.split(";"), function (ii, cssStyle) {
                                if (cssStyle != "") {
                                    step = 14;
                                    styles[cssStyle] = true;
                                }
                            });
                        });
                        step = 15;
                        for (x in styles) {
                            step = 16;
                            tools.decorate(decoration, x);
                        }
                    }
                }
            }
        } catch (e) {
            OK.logging.logger.error("ta.e", "highlight_e_" + step);
            return;
        }
    },

    decorate: function (decoration, cssStyle) {
        decoration.find("[data-style='" + cssStyle.trim() + "']").addClass(util.decorationSelectedCss);
    },

    cleanFormatting: function () {
        var $ = jQuery;

        $('.decoration').find('.' + util.decorationSelectedCss).removeClass(util.decorationSelectedCss); // TODO нужно оптимальней выбирать ноды после того как будет готова конечная верстка тулбара
    },

    beforeSendMsg: function() {
        var $el = OK.editor.el;
        if ($el.saveText) {
            $el.saveText.clearText($el);
        }
    },

    setCaretToEnd: function (force) {
        var editor = OK.editor,
            selector = editor.selector,
            util = editor.util,
            step = 0,
            el = editor.el,
            element;

        try {
            step = 1;
            // todo: разобраться почему же всё-таки нужно столько проверять
            // если фокус находится внутри текстарии и мы вставляем текст - необходимо двинуть каретку в конец
            if((!force && editor.hasFocus) || !el || typeof el == "undefined" || typeof jQuery == "undefined" || !(el instanceof jQuery) || el.size() < 1) {
                return;
            }

            step = 3;
            element = el[0];
            step = 4;
            this.setFocus(false);
            step = 5;

            if (selector.supportsRange()) {
                step = 6;
                var range = document.createRange(),
                    sel = selector.getSelection(),
                    collapseToStart = false,
                    caretPos = editor.caretPosition;

                step = 7;
                if (sel && range) {
                    // Выбираем, ставить каретку на запомненную позицию или, если позиция не запомнена, в конец строки
                    // TODO: перенести проверку util.isInEditor(selection.anchorNode) в метод saveCaretPosition, если убедимся,
                    // что не он тормозит работу редактора при onKeyUp
                    if (caretPos && range.setStart && caretPos.anchorNode && util.isInEditor(caretPos.anchorNode) && caretPos.anchorOffset) {
                        step = 8;
                        // позиция каретки уже была сохранена, надо вернуться на неё
                        range.setStart(caretPos.anchorNode, caretPos.anchorOffset);
                    } else {
                        step = 9;
                        // позиция каретки не сохранена, значит ставим в самый конец
                        range.selectNodeContents(element);
                    }

                    step = 12;
                    range.collapse(collapseToStart);
                    step = 13;
                    if (typeof sel.removeAllRanges != "undefined") {
                        sel.removeAllRanges();
                    }
                    step = 14;
                    sel.addRange(range);

                    step = 15;
                    this.highlightFormatting();
                }

            } else if (selector.supportsTextRange()) { //IE <= 8
                step = 16;
                var textRange = document.body.createTextRange();
                step = 17;
                if (textRange) {
                    step = 18;
                    textRange.moveToElementText(element);
                    step = 19;
                    textRange.collapse(false);
                    step = 20;
                    textRange.select();
                }
            }
        } catch (e) {
            OK.logging.logger.error("ta.e", "setCaretJs_e_" + step);
            return;
        }
    },

    /**
     * Устанавливаем фокус
     * @param sureIsVisible — false, если не уверены, что на момент вызова ТА видна. Тогда для IE7,8 применим фикс с setTimeout()
     */
    setFocus: function(sureIsVisible) {
        var editor = OK.editor,
            selector = editor.selector,
            el = editor.el,
            step = 0,
            element;

        try {
            if(editor.hasFocus || !el || typeof el == "undefined" || el.size() < 1) {
                return;
            }
            step = 1;
            element = el[0];
            step = 2;

            if (selector.supportsRange() || sureIsVisible) {
                step = 3;
                element.focus();
            } else {
                // При установки фокуса на невидимый элемент в IE7, 8 вылетает эксепшн из-за того, что фокусируемый объект не видем.
                // Однако, если вызывать фокус через setTimeout, то проблема исчезает.
                step = 4;
                setTimeout(
                    function() {
                        element.focus();
                    }, 10
                );
            }

        } catch (e) {
            OK.logging.logger.error("ta.e", "setFocus_e_" + step);
            return;
        }
    }
};

/* Util */

OK.editor.util = {
    zeroWidthSpace: String.fromCharCode(8203), /*браузеры завязаны на <br/> и высоту строки по символу в блочных элементах, пробелом нулевой ширины, можно пофиксить высоту*/
    decorationSelectedCss: 'd-selected',

    isWebKit: false,
    isSafari: false,
    isFireFox: false,
    ie11: false,

    setActiveElement: function (id) {
        try {
            if (typeof jQuery == "undefined") {
                return;
            }

            if (id === "ok-e-d") {
                if (typeof OKdiscussions == "undefined" || OKdiscussions.size() < 1) {
                    OKdiscussions = jQuery("#" + id);
                }

                OK.editor.el = OKdiscussions;
            }
        } catch (e) {
            OK.logging.logger.error("ta.e", "setActiveElementJs_e_" + step);
        }
    },

    isParagraph: function (el) {
        if (typeof el != "undefined"){
            var nodeName = el.nodeName.toUpperCase();
            if (nodeName == "BR" || nodeName == "DIV" || nodeName == "P") {
                return true;
            }
        }
        return false;
    },

    isSpan: function (node) { return this.isElement(node, 'SPAN'); },

    isImg: function (node) { return this.isElement(node, 'IMG'); },

    isElement: function (node, name) { return node && node.nodeType == 1 && node.tagName.toUpperCase() == name; },

    isSpace: function (charCode) { return charCode == 160 || charCode == 32; },

    isText: function (refNode) { return refNode && refNode.nodeType == 3; },

    isEmpty: function (element) { return util.isEmptyText(util.cleanZeroWidth(element.innerHTML)); },

    isEmptyText: function (text) { return util.cleanZeroWidth(text).length == 0; },

    isEmptyElement: function (el) { return typeof el != "undefined" && (el.innerHTML == util.zeroWidthSpace || util.isEmpty(el) || util.hasOnlyBr(el)); },

    /**
     * Подготовим html перед отправкой.
     * Проверим, что кроме пробелов и <br> там ещё какой-нибудь контент есть.
     */
    getAreaText: function () {
        var el = OK.editor.el,
            step = 0,
            reg,
            text = el.text(),
            html = el.html();

        try {
            if (el) {
                step = 1;
                text = el.text();
                step = 2;
                html = el.html();

                step = 3;
                // проверим, есть ли полезная информация в тексте, удалим пробелы и переносы строк
                if (text.trim() == "") {
                    step = 4;
                    //если нет плайн текста, проверяем есть ли смайлики
                    reg = /[<]img[^>]* alt=[^>]+[>]/ig;
                    step = 5;
                    if (!reg.test(html)) {
                        return "";
                    }
                }

                step = 6;
                return this.prepareText(html);
            }

            return "";
        } catch (e) {
            OK.logging.logger.error("ta.e", "getAreaText_e_" + step);
            return "";
        }
    },

    /**
     * Подготовим html перед отправкой на сервер
     */
    prepareText: function (original) {
        var step = 0,
            html = original;

        try {
            if (html) {
                step = 1;
                //перед субмитом вырезаем тэги, которые мы не поддерживаем.
                //оборачиваем поддерживаемые тэги в [%%[ТЭГ%%]
                html = html.replace(
                    /(<(\/?(strong|b|em|i|strike|a|u|p|br|img|div|span|font)(([ ][^>]*)|([\/]?)))>)/gi,
                    "[%%[$2]%%]");
                step = 2;
                //удаляем все оставшиеся тэги, так как у наших тэгов уже нет ни "<" ни ">"
                html = html.replace(/<[^>]*>/gi, "");
                step = 3;
                //восстанавливаем обрамление наших тэгов
                html = html.replace(/(\[%%\[([^\]]*)\]%%\])/gi, "<$2>");
                step = 4;
                // для тагов атрибуты которых начинаются с ' убираем все атрибуты
                // <span style="asd='asd" >asd</span>
                html = html.replace(
                    /(<\w+)(?=[^>]*=\s*')(?:[^\/>]*(?:[\/][^>])?)*([\/]?>)/gi,
                    "$1$2");

                step = 5;
                return html.replace(/&nbsp;/g, ' ');
            }

            return "";
        } catch (e) {
            OK.logging.logger.error("ta.e", "prepareTextJs_e_" + step);
            return original; // дадим ещё один шанс на проверке на сервере
        }
    },

    cleanZeroWidth: function (text) { // удалим все пробелы нулевой ширины из текста
        while(text.indexOf(this.zeroWidthSpace) != -1) text = text.replace(this.zeroWidthSpace, '');
        return text;
    },

    isEditor: function (node) { return !util.isText(node) && $(node).hasClass(OK.editor.JS_CLASS); }, // у <div> редактора должен быть класс редактора

    hasOnlyBr: function (startContainer) { return startContainer.childNodes.length == 1 && util.isElement(startContainer.firstChild, 'BR'); },

    after: function (refNode, newNode) {
        this.checkScheme(refNode.parentNode, newNode);
        if (refNode.nextSibling) {
            refNode.parentNode.insertBefore(newNode, refNode.nextSibling);
        } else {
            refNode.parentNode.appendChild(newNode);
        }
    },

    before: function (refNode, newNode) {
        this.checkScheme(refNode.parentNode, newNode);
        refNode.parentNode.insertBefore(newNode, refNode);
    },

    throwSchemeViolation: function () { /* alert('scheme violation!'); throw 'scheme violation'; */}, // TODO: log me

    throwSelectionError: function () { /* alert('selection error!'); throw 'selection error'; */},    // TODO: log me

    /** Проверка изменений, чтобы они не нарушали схему документа */
    checkScheme: function (appendNode, newNode) {
        if ((this.isSpan(appendNode) && this.isSpan(newNode))
            || this.isImg(appendNode)) {
            this.throwSchemeViolation();
        }
    },

    /**
     * Находится ли focusNode в редакторе
     * @param focusNode
     * @param activeEditor — необязательный параметр. При наличии дополнительно проверяется активный ли найденный редактор
     */
    isInEditor: function (focusNode, activeEditor) {
        while (focusNode) {
            if (this.isEditor(focusNode)) {
                if (activeEditor && activeEditor.id != focusNode.id) {
                    return false;
                }
                return true;
            }
            focusNode = focusNode.parentNode;
        }
        return false;
    },

    /** Создаем DOM-элемент с опереденным стилем @param cssStyle и контентом @param content можеты быть текстом или нодой */
    createEl: function (el, cssStyle, content) {
        this.formatCss(el, cssStyle);
        if (content.nodeType) {
            this.append(el, content);
        } else {
            this.append(el, document.createTextNode(content));
        }
        return el;
    },

    /** Создаем <span> с опереденным стилем @param cssStyle и контентом @param content можеты быть текстом или нодой */
    createSpan: function (cssStyle, content) {
        var span = document.createElement('span');
        return this.createEl(span, cssStyle, content);
    },

    /** Наследуем оформление с предыдущего span (клонируем его без детей) и добавляем новый класс и контент */
    inheritSpan: function (node, cssStyle, content) {
        var span;
        if (node && this.isSpan(node)) {
            span = node.cloneNode(false);
        } else {
            span = document.createElement('span');
        }
        if (cssStyle) {
            this.formatCss(span, cssStyle);
        }
        if (content) {
            if (content.nodeType) {
                this.append(span, content);
            } else {
                $(span).text(content);
            }
        }
        return span;
    },

    formatCss: function (element, cssStyle, removeSameStyle) {
        var el = $(element),
            split = cssStyle.split(":"),
            propertyName = split[0].trim();

        if (removeSameStyle && OK.editor.command.hasSameStyle(element, cssStyle)){
            el.css(propertyName,""); //удаляем уже ненужный стиль
        } else {
            el.css(propertyName,split[1].trim()); // устанавливаем стиль
        }
    },

    /** Перемещаем всех sibling после @param refNode в @param targetNode @param validate проверять ли схему */
    moveAllAfter: function (refNode, targetNode, validate) {
        var sibling;
        while (refNode.nextSibling) {
            sibling = refNode.nextSibling;
            $(sibling).remove();
            util.append(targetNode, sibling, validate);
        }
    },

    /** Перемещаем всех sibling до @param refNode в @param targetNode */
    moveAllBefore: function (refNode, targetNode) {
        var sibling, parent = refNode.parentNode;
        while (parent.firstChild != refNode) {
            sibling = parent.firstChild;
            $(sibling).remove();
            this.append(targetNode, sibling);
        }
    },

    append: function (parent, newNode, validate) {
        if (validate) util.checkScheme(parent, newNode);
        parent.appendChild(newNode);
    },

    replace: function (refNode, newNode) {
        if (this.isEditor(refNode)) {
            this.throwSchemeViolation();
        }
        this.checkScheme(refNode.parentNode, newNode);
        refNode.parentNode.replaceChild(newNode, refNode);
    },

    /** Фикс правильно проставления фокуса */
    normalizeFocus: function (element) {
        var emptyTextNode;
        if (element.nextSibling && !(util.isText(element.nextSibling) && element.nextSibling.nodeValue.length == 0)) {
            element = element.nextSibling; // HACK nextSibling иначе фокус не выставляется правильно в хроме
        } else {
            emptyTextNode = document.createTextNode('');
            util.after(element, emptyTextNode);
            element = emptyTextNode;
        }
        return element;
    },

    /** В некоторых случаях надо сдвинуть фокус с доп параметрами, которые могут быть text: и offset: */
    moveFocus: function (element, params) {
        var refNode, range = document.createRange(), focus;
        if (!params) params = { text: util.zeroWidthSpace, offset: 0 };
        focus = document.createTextNode(params.text);

        refNode = element.firstChild || element;
        util.after(refNode, focus);

        range.setStart(focus, params.offset); // FF
        selector.addRange(range);

        this.saveCaretPosition();
    },

    /**
     * Получаем крайние ноды из диапазона
     * @param range
     * @param container элемент от которого отсчитывается offset
     * @param offset
     * @param start старт или конец диапазона
     * @returns выбранная нода
     */
    getSelectedNode: function (range, container, offset, start) {
        var result, end = !start, selected = false, pos = 0, e;
        if (util.isText(container) ||
            container.childNodes.length == 0) { // выбран пустой <div/> например
            return container;
        } else {
            result = container.childNodes[offset];
            if (!result) { // может указываться оффсет больше чем детей в ноде
                result = container.lastChild;
                selected = true;
            }
            if (!result) util.throwSelectionError();
            if (end && (
                    (util.isWebKit && util.isText(result)) // если текст указан такой нодой, значит он не выбран (замечено только в Chrome)
                    || ((util.isFireFox || (util.isWebKit && !selected)) && util.isImg(result)) // ФФ указывает следующую ноду, которая не выделена пользователем
                )) {
                result = util.find(result, false);
                if (util.isFireFox) result = result.lastChild || result; // <s><i/>[1][2]</s>|<i> конечным контейнером должен быть не <span> а его последняя нода
            }
            return result;
        }
    },

    /** Ищем следующую/предыдущую по дереву ноду, если ее нет, то спускаемся на родителя и т.п.  */
    find: function (current, next) {
        var sibling = next ? 'nextSibling' : 'previousSibling', nextNodeSource = current.parentNode, pointNode = current[sibling];
        while (pointNode == null && nextNodeSource) { // ? может уйти вне editor'а?
            pointNode = nextNodeSource[sibling];
            nextNodeSource = nextNodeSource.parentNode;
        }
        if (!util.isInEditor(pointNode)) return null; // TODO часто проходит до родителя, надо бы оптимизировать
        return pointNode;
    },

    /** Ищем следующую/предыдущую по дереву ноду <BR>, если ее нет, то спускаемся на родителя и т.п.  */
    findBr: function (current, forward) {
        var sibling = forward ? 'nextSibling' : 'previousSibling', nextNodeSource = current.parentNode, pointNode = current, isBr = pointNode.nodeName == "BR", lastNode = pointNode;

        while (pointNode != null) { // ? может уйти вне editor'а?
            if (this.isParagraph(pointNode)) {
                return pointNode;
            }

            var next = pointNode[sibling];
            if (next != null) {
                if(this.isParagraph(next)) {
                    return pointNode;
                }
                pointNode = next;
            } else if (pointNode.parentNode != null && !this.isEditor(pointNode.parentNode)){
                pointNode = pointNode.parentNode;
            } else {
                return pointNode;
            }
        }
    },

    /** Удаляем @param refNode из текущего места и перемещаем ее в @param targetNode */
    move: function (refNode, targetNode) {
        $(refNode).remove();
        this.append(targetNode, refNode);
    },

    /**
     * Инициализация
     *
     * @param id                — айдишник элемента, который хочет стать текстареей
     * @param sendOnCtrlEnter   — посылать ли сообщение на ctrl + enter (настройка в IUserLayoutHint)
     * @param jsniBridge        — набор методов для обратной связи с JSNI
     * @param params            — дополнительные параметры
     */
    staticInit: function (id, sendOnCtrlEnter, jsniBridge, params) {
        var el,
            allowedOnPaste,
            options,
            t,
            editor = OK.editor,
            util = editor.util,
            harness = editor.harness;

        util.setActiveElement(id);

        el = editor.el;
        if (el.size() < 1) {
            // ждём, пока элемент появится в DOM'e
            setTimeout(
                function() {util.staticInit(id, sendOnCtrlEnter, jsniBridge, params)},
                100
            );
            return;
        }

        // сохраняем в поле JSNI-методы, к которым можем обратиться из JS
        el.bridge = jsniBridge;

        options = $.extend({}, {
            formattingDisabled: false,
            allowedTags: "",
            saveTextInLS: false
        }, params);

        el.on({
            click: function () {
                harness.click()
            },
            paste: function () {
                var _t = $(this);
                setTimeout(function () {
                    if (options.allowedTags) {
                        util.cleanPaste(_t, options.allowedTags);
                    } else {
                        util.cleanPasteOld(_t, allowedOnPaste); // очистка вставки
                    }
                    util.updateSrollStyles();
                    el.bridge.updateLengthCounter('paste');
                }, 50);
            },
            keydown: function (e) {
                if (options.formattingDisabled && (e.ctrlKey || e.metaKey) && (e.keyCode === 66 || e.keyCode === 73 || e.keyCode === 85)) {
                    // запрещаем делать bold, italic и underline с помощью шорткатов клавиатуры
                    e.preventDefault();
                }
            },
            focus: function () {
                editor.hasFocus = true;
                el.bridge.updateLengthCounter('focus');
                el.bridge.onFocus();
                util.updateIsEmpty();
            },
            blur: function () {
                editor.hasFocus = false;
                util.updateIsEmpty();
            }
        });

        el.on("keypress", function (event) {
                harness.onKeyDown(event, sendOnCtrlEnter)
            }
        );

        // при добавлении смайла обновляем стили
        el.on('textchange', function() {
            t && clearTimeout(t);
            t = setTimeout(function() {
                util.updateSrollStyles();
            }, 500);
        });

        if (options.saveTextInLS) {
            require(['OK/saveText'], function () {
                el.saveText({
                    // после восстановления недописанного текста
                    afterRestoreValue: function() {
                        t && clearTimeout(t);
                        t = setTimeout(function() {
                            tools.setCaretToEnd(true);
                            util.onSetText();
                        }, 100);
                    }
                });
            });
        }

        util.hideLoadingProcess(id);

        util.updateSrollStyles(true);

        util.updateIsEmpty();
    },

    /**
     * Инициализируем выпадащки смайликов и форматирования текста
     * @param id — аттрибут id DOM-элемента, в котором находится инициализируемая выпадашка
     * @param type — тип выпадашки, smiles или format
     */
    initDropdown: function (id, type) {
        var fn, node, duration,
            startTime = new Date().getTime(),
            logger = OK.logging.logger,
            editor = OK.editor,
            util = editor.util,
            tools = editor.tools;

        if (type == "format") {
            node = "A"; // <a href="" />
            fn = function(target){
                var dataAttrName = "data-style",
                    selectedClass = util.decorationSelectedCss,
                    t = $(target),
                    styleAttr = t.attr(dataAttrName),
                    styleValue;

                // В data-style атрибуте до первого клика храним название css-свойства, которым нужно форматировать.
                // После клика туда проставляем пару название: значение (text-align: center), которое берём из атрибута style.
                // Это требуется для того, чтобы сохранять запись стилей таком ввиде, как хранит его конкретный браузер:
                // <a style="font-weight: bold" data-style="font-weight" ></a>
                if (styleAttr.indexOf(":") == -1) {
                    styleValue = t.css(styleAttr);

                    if (styleAttr === OK.editor.DECORATION_STYLE_NAME) {
                        styleValue = styleValue.split(" ")[0];
                    }
                    styleAttr = styleAttr + ": " + styleValue;

                    t.attr(dataAttrName, styleAttr);
                }

                logger.success("ta", "format", styleAttr.split(":")[0]); // логируем какой тип оформления кликнули

                editor.command.format(
                    t.hasClass(selectedClass),
                    styleAttr
                );
            };
        } else if (type == "smiles") {
            node = "IMG"; // <img />
            fn = function(target){
                logger.success("ta", "smile");

                tools.insertHtml(target);
            };
        }

        // клики отлавливаем по всему дропдауну, но отрабатываем только по попаданию в необходимый элемент — A или IMG
        $('#' + id)
            .on({
                mousedown: false, // отменяем событие mousedown, чтобы фокус не успел уйти с текстареи
                click: function (e) {
                    e.preventDefault();

                    // возвращаем фокус, если всё-таки успел уйти
                    if(editor.selector.supportsSelection()) {
                        tools.setCaretToEnd();
                    } else {
                        // IE7, 8 умеет запоминать положение каретки, поэтому достаточно только установить фокус
                        tools.setFocus(true);
                    }

                    // нам нужны только клики по ссылкам, остальное пропускаем
                    var target = e.target;
                    if (target.nodeName.toUpperCase() == node) {
                        fn(target);
                    }
                }
            });

        duration = (new Date().getTime() - startTime);
        if (duration > 0) {
            logger.durationScalar("ta", type + "_duration", duration);
        }
    },

    /**
     * Убираем крутилку «загрузка» с кнопки отсылки
     */
    hideLoadingProcess: function(id) {
        var sendButton,
            loadingClass = "__loading"; // DiscussionsConstants.CLASS_TA_BUTTON_LOADING

        sendButton = document.getElementById(id + "_button");
        if (sendButton == null) return;

        sendButton.classList.remove(loadingClass);
    },

    /**
     * Фикс http://screencast.com/t/tUOGpdeQrzV
     * Удаляет "унаследованное" оформление через <font/>
     */
    normalizeFont: function () {
        var focus, parent, range;
        if (selector.supportsRange()) {
            range = selector.getRange();
            var isBrowserGeneratedTag = util.isElement(selector.getFocusElement(range), 'FONT') ||
                (util.isElement(selector.getFocusElement(range), 'B') && util.isElement(selector.getFocusElement(range).parentNode, 'FONT'));

            if (range && (util.isSafari || util.isWebKit) && isBrowserGeneratedTag) {
                focus = selector.getFocusNode();
                parent = focus.parentNode;
                range = document.createRange();
                var contents = $(parent).contents(); // возможно стоит скопипастить метод, чтобы не вызвать баги в редакторе
                $(parent).replaceWith(contents);
                range.setStart(focus, focus.length);
                selector.addRange(range);
            }
        }
    },

    /** Удалим html-комментарии (<!-- -->). В них MS Word хранит свои стили. */
    cleanWordComments: function (element) {
        element.contents().filter(function() {
            return this.nodeType == 8;
        }).remove();
    },

    cleanPasteOld: function (element, allowedTags) {
        this.cleanWordComments(element);

        // TODO: вырезать все картинки кроме смайликов

        // Удаляем все тэги, которые могли попасть не с нашего портала
        if (allowedTags) {
            element.html(util.stripTags(element.html(), allowedTags));
        }

        var next = element.get(0).firstChild, afterSpan, parent, last, $next;

        while (next) {
            $next = $(next);
            if (!$next.hasClass("emoji")) { // emoji не трогаем
                $next.removeAttr('class'); //удаляем атрибут class
            }
            if (util.isSpan(next) && util.isSpan(next.parentNode)) { // пофиксим схему документа после вставки TODO потенциально затратная операция, возможно стоит подумать как соптимайзить
                parent = next.parentNode;
                afterSpan = util.inheritSpan(parent);
                util.moveAllAfter(next, afterSpan, false);
                util.after(parent, afterSpan);
                $next.remove();
                util.before(afterSpan, next);
                last = next;
            }
            next = next.firstChild || util.find(next, true);
        }
        if (last) {
            util.moveFocus(last); // сдвинем фокус на последнее исправление
        }
    },

    cleanPaste: function (element, allowed) {
        // сохраним позицию каретки перед тем, как менять DOM-структуру
        util.saveCaretPosition();

        // Удалим html-комментарии (<!-- -->). В них MS Word хранит свои стили.
        element.find("*").addBack().contents().filter(function() {
            return this.nodeType === 8;
        }).remove();

        // все теги из ТА, кроме резрешённых
        var allBut = element.find("*:not(" + allowed + ")");
        // Удаляем ненужные теги, «разворачивая» из них содержимое
        allBut.contents().unwrap();

        // Удаляем оставшиеся void-элементы (без закрывающего тега). Наример, hr, input, img
        allBut.remove();

        // Удаляем атрибуты (включая class и style) для всех элементов кроме эмоджи и пользовательских смайлов
        // Для случаев, когда пользователь копирует смайл правой клавишей мыши (в этом случае не вставляется класс картинки) нужно матчить по наличию в альте #u s#
        element.find("*:not(img.emoji, img.usmile, img[alt^='#u'][alt$='s#'])").each(function(){
            var attributes = this.attributes;
            var i = attributes.length;
            while(i--) {
                var attr = attributes[i];
                this.removeAttributeNode(attr);
            }
        });

        /*
            Если в ТА остался один параграф с текстом, то выкидываем этот параграф за ненадобностью, оставляя текст без него
          */
        var children = element.children(),
            onlyOneChild = children.size() == 1;

        if (onlyOneChild) {
            children.contents().unwrap();
        }

        /*
         Если производились большие изменения в ДОМ-дереве, то каретка потеряет свою позицию. Ставим на сохранённую до изменений позицию.
         */
        if (onlyOneChild || allBut.size() > 0) {
            // нормализуем (объединяем) текстовые ноды, появившиеся из-за удаления тегов
            element.get(0).normalize();
            tools.setCaretToEnd(true);
        }
    },

    /**
     * Запомним положение каретки.
     *
     * К сожалению, при клике в другие элементы Selection на тот элемент переходит быстрее, чем событие blur в нашей текстарее.
     * Поэтому приходится сохранять положение каретки при каждом егго изменении — на keyup и mouse-click.
     */
    saveCaretPosition: function () {
        var editor = OK.editor,
            selector = editor.selector,
            selection;

        if (editor.hasFocus && selector.supportsSelection()) { // IE > 8
            selection = editor.selector.getSelection();
            if (selection) {
                editor.caretPosition = $.extend({}, selection); // копируем объект Selection
            }
        }
    },

    /**
     * Strips HTML tags from a string
     * © http://stackoverflow.com/questions/4122451/tinymce-paste-as-plain-text
     *
     * @param allowed_tags — таги, которые разрешено оставить
     */
    stripTags: function (str, allowed_tags) {
        var key = '',
            allowed = false,
            matches = [],
            allowed_array = [],
            allowed_tag = '',
            i = 0,
            k = '',
            html = '';

        try {
            var replacer = function (search, replace, str) {
                return str.split(search).join(replace);
            };

            // Build allowes tags associative array
            if (allowed_tags) {
                allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
            }
            str += '';

            // Match tags
            matches = str.match(/(<\/?[\S][^>]*>)/gi);
            // Go through all HTML tags
            for (key in matches) {
                if (isNaN(key)) {
                    // IE7 Hack
                    continue;
                }

                // Save HTML tag
                html = matches[key].toString();
                // Is tag not in allowed list? Remove from str!
                allowed = false;

                // Go through all allowed tags
                for (k in allowed_array) {            // Init
                    allowed_tag = allowed_array[k];
                    i = -1;

                    if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+'>');}
                    if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+' ');}
                    if (i != 0) { i = html.toLowerCase().indexOf('</'+allowed_tag)   ;}

                    // Determine
                    if (i == 0) {                allowed = true;
                        break;
                    }
                }
                if (!allowed) {
                    str = replacer(html, "", str); // Custom replace. No regexing
                    OK.logging.logger.success("ta", "strip");
                }
            }
            return str;
        } catch (e) {
            OK.logging.logger.error("ta.e", "stripTags_e_" + step);
            return str;
        }
    },

    /**
     * @returns {boolean} есть ли скролл у ТА
     */
    hasScroll: function() {
        var step = 0;
        try {
            if (!OK.editor.el) {
                return false;
            }
            step = 1;
            var el = OK.editor.el[0],
                direction = "scrollTop";

            if (typeof el == "undefined") {
                return false;
            }

            step = 2;
            var result = !! el[direction];

            step = 3;
            // если scrollTop == 0, попробуем дёрнуть скролл на один пиксель, чтобы убедиться что его нет
            if (!result) {
                step = 4;
                el[direction] = 1;
                step = 5;
                result = !!el[direction];
                step = 6;
                el[direction] = 0;
            }

            step = 7;
            return result;
        } catch (e) {
            OK.logging.logger.error("ta.e", "hasScroll_e_" + step);
            return false;
        }
    },

    /**
     * При появлении/пропадании скрола нужно подвинуть тулбар со смайлами и аттачами
     * @param {boolean} force не проверять предыдущее состояние, обновить в любом случае
     */
    updateSrollStyles: function(force) {
        var step = 0;
        try {
            if (typeof jQuery == "undefined") {
                return;
            }
            step = 1;
            var $ = jQuery,
                has = util.hasScroll();

            step = 2;
            if (!force && OK.editor.hasScroll === has) {
                return;
            }
            step = 3;

            // сохраняем наличие или отсутсвие скрола
            OK.editor.hasScroll = has;
            step = 4;
            if (!OK.editor.toolbar) {
                step = 5;
                // Пока что иконки смайлов и аттача находятся внутри ТА только в сообщениях
                OK.editor.toolbar = $(".topPanel_m .disc_toolbar_w");
            }
            step = 6;

            OK.editor.toolbar.toggleClass("__has-scroll", has);
        } catch (e) {
            OK.logging.logger.error("ta.e", "updateSrollStyles_e_" + step);
        }
    },

    /**
     * Навесим или уберём класс-признак о том пустая ли ТА.
     * Необходимо для того, чтобы показывать и прятать плейсхолдер и каретку, добавляемые псевдоэлементами.
     * (К сожалению, :empty плохо работает в IE11, приходится проставлять __empty через JS)
     *
     * NB: Сейчас обновляется только на инициализацию, blur и focus.
     */
    updateIsEmpty: function() {
        var el = OK.editor.el;
        if (el.hasClass('add-placeholder') || el.hasClass('add-caret')) {
            setTimeout(function() {
                el.toggleClass("__empty", util.getAreaText().length === 0);
            }, 0);
        }
    },

    initMentions: function (id, mentionsCharStart, defer, util, discussionId, groupMentionsEnabled) {
        var el = document.getElementById(id);
        require(['OK/postingForm/mentions', 'OK/pts!discussion.client', 'OK/suggest/searchEngines'],
            function (mentionsFactory, pts, searchEngines) {
                var mentions = mentionsFactory('cfmdsc_' + new Date().getTime());
                var selector = OK.editor.selector;
                mentions.mentionsCharStart = mentionsCharStart || 3;
                mentions.cleanupOnPaste = false;
                mentions.useText = true;
                var searchEngine;
                if (discussionId) {
                    searchEngine = searchEngines.commentMentionsEngine(discussionId, groupMentionsEnabled);
                }

                mentions.init(pts.getLMsg('markFriendDisabled'), util, selector, true, searchEngine);
                defer.resolve(mentions.bind(el));
            });
    },

    /**
     * При установке текста из вне
     */
    onSetText: function() {
        util.updateIsEmpty();

        // дёргаем keypress, чтобы saveText знал о том, что текст в ТА изменился.
        // нужно для случая, когда послали сообщение кнопкой «отправить», а не enter'ом.
        OK.editor.el.trigger("keypress");

        // скидываем подсвеченное форматирование
        tools.cleanFormatting();
    }
};

var harness = OK.editor.harness,
    selector = OK.editor.selector,
    tools = OK.editor.tools,
    util = OK.editor.util;

OK.editor.command = {

    /**
     * Выбор форматировать диапазон или места фокуса в определенный стиль.
     * @param cssSelected текущее оформление было выбрано
     * @param cssStyle проставляемый класс
     */
    format: function (cssSelected, cssStyle) {
        // Объединяем несколько TextNode внутри общего тэга http://my.safaribooksonline.com/book/-/9781449344498/7dot-text-nodes/combine_sibling_text_nodes_into_one_html
        OK.editor.el.get(0).normalize();

        var range, startContainer, endContainer, util = OK.editor.util;
        if (selector.supportsRange()) {
            range = selector.getRange()
            if (range) {
                startContainer = util.getSelectedNode(range, range.startContainer, range.startOffset, true);
                endContainer = util.getSelectedNode(range, range.endContainer, range.endOffset, false);
                if (!startContainer || !endContainer) util.throwSelectionError();

                // text-align — нужно создавать div, а не span
                if (cssStyle.indexOf("text-align") != -1) {
                    this.formatDiv(range, startContainer, endContainer, cssStyle)
                } else {
                    if (range.collapsed) { // selection.isCollapsed
                        this.formatSingle(range, startContainer, endContainer, cssStyle, cssSelected);
                    } else {
                        this.formatRange(range, startContainer, endContainer, cssStyle, cssSelected);
                    }
                }
                tools.highlightFormatting();
            }
        }
    },

    formatSpan: function (current, cssStyle, cssSelected) {
        OK.editor.util.formatCss(current, cssStyle, true);
    }

    , /**
     * Форматирование диапазона
     * @param range
     * @param startContainer начальный контейнер
     * @param endContainer конечный контейнер
     * @param cssClass проставляемый класс
     * @param cssSelected было выбрано
     */
    formatRange: function (range, startContainer, endContainer, cssClass, cssSelected) {
        var next, current, end, clone, util = OK.editor.util;
        this.splitTextNodes(range, startContainer, endContainer);
        this.splitSpans(range, startContainer, endContainer);
        next = startContainer;
        while (next) {
            current = next;
            end = current === endContainer;
            if (end) {
                next = null;
                selector.setEnd(range, current);
            } else {
                next = next.firstChild || util.find(next, true);
            }

            if (util.isImg(current)) {
                continue;
            } else if (util.isText(current)) {
                if (util.isSpan(current.parentNode)) {
                    this.formatSpan(current.parentNode, cssClass, cssSelected);
                } else {
                    if (util.isSpan(current.previousSibling)) {
                        util.move(current, current.previousSibling);
                    } else {
                        clone = current.cloneNode(false);
                        util.replace(current, util.createSpan(cssSelected ? '' : cssClass, clone));
                        if (end) {
                            selector.setEnd(range, clone);
                        }
                    }
                }
            } else if (util.isSpan(current) && current.childNodes.length == 0) { // бывает у <span/> не детей
                this.formatSpan(current, cssClass, cssSelected);
            }
        }
        selector.addRange(range);
    },

    /**
     * Выравнивание текста text-align — создаём div и вставляем в него все элементы от BR (или DIV, P) до BR
     * @param range
     * @param startContainer начальный контейнер
     * @param endContainer конечный контейнер
     * @param cssClass проставляемый класс
     * @param cssSelected было выбрано
     */
    formatDiv: function (range, startContainer, endContainer, cssStyle) {
        var next, div, content, last,
            util = OK.editor.util,
            frag = document.createDocumentFragment();

        if(!OK.editor.el.get(0).hasChildNodes()) { //пустая текстарея TODO: зарефачить
            div = document.createElement('div');
            util.formatCss(div, cssStyle, false);
            util.append(div, document.createTextNode(String.fromCharCode(8203)));
            util.append(startContainer, div);


            var range = document.createRange();
            var sel = window.getSelection();
            range.setStart(div, 0);
            range.collapse(false);
            sel.removeAllRanges();
            sel.addRange(range);

            return;
        }

        // каретка стоит в конце строки, значит нужно начинать, включив эту строку
        if (startContainer.nodeName.toUpperCase() == "BR" && startContainer.previousSibling && !util.isParagraph(startContainer.previousSibling)) {
            startContainer = startContainer.previousSibling;
        }

        next = util.findBr(startContainer, false);
        last = util.findBr(endContainer, true);

        if (next.nodeName.toUpperCase() == "DIV") {
            // div уже создан, нужно всего лишь поменять text-aligment
            div = next;
            util.formatCss(div, cssStyle, true);
        } else {
            // создадим новый див
            div = document.createElement('div');
            util.formatCss(div, cssStyle, false);

            // упаковываем ноды в новый див до первого <br/>
            do {
                content = next;
                next = content.nextSibling;
                // переносим контент в див
                util.append(div, content);
            } while (next && !util.isParagraph(next));

            // удаляем BR после нового параграфа, но не самый последний (т.к. он автоматически добавляетя браузерами)
            if (next && next.nodeName.toUpperCase() == "BR" && next.nextSibling) {
                content = next;
                next = content.nextSibling;
                $(content).remove();
            }

            //если div получился пустым (выравнивали новую строку), то нужно добавить в него пробел нулевой длины
            if(!div.innerHTML) {
                util.append(div, document.createTextNode(String.fromCharCode(8203)))
            }

            frag.appendChild(div);
        }

        if (frag.hasChildNodes()) {
            range.insertNode(frag);
            util.moveFocus(div);
        }
    },

    /**
     * Разбиение тектовых нод, если одна, то обрезаем справа и слева и добавляем с этих концов.
     * Если диапазон влючает несколько нод, то соотвественно слева вначале режем и справа в конце.
     * @param range
     * @param startContainer
     * @param endContainer
     */
    splitTextNodes: function (range, startContainer, endContainer) {
        var startNodeValue = startContainer.nodeValue, before, util = OK.editor.util,
            endNodeValue = endContainer.nodeValue, after,
            startChanged = util.isText(startContainer) && range.startOffset > 0, // будем что-то менять если только произошло реальное изменение
            endChanged = util.isText(endContainer) && range.endOffset < endNodeValue.length;
        if (startChanged) {
            before = startNodeValue.substr(0, range.startOffset);
            if (util.cleanZeroWidth(before).length > 0) util.before(startContainer, document.createTextNode(before));

        }
        if (endChanged) {
            after = endNodeValue.substr(range.endOffset);
            if (util.cleanZeroWidth(after).length > 0) util.after(endContainer, document.createTextNode(after));
        }
        if (startContainer === endContainer && util.isText(startContainer)) { // если нода одна
            startContainer.nodeValue = startNodeValue.substr(range.startOffset, range.endOffset - range.startOffset); // тут при вставке бывает создаются лишние пустые <span>
        } else {
            if (startChanged) startContainer.nodeValue = startNodeValue.substr(range.startOffset);
            if (endChanged) endContainer.nodeValue = endNodeValue.substr(0, range.endOffset);
        }
    },

    /**
     * Разбиваем ноды, чтобы в выделенном диапазоне были только целые ноды.
     * Слева и справа разбивается примерно также как и в {@see splitTextNodes}
     * @param range
     * @param startContainer
     * @param endContainer
     */
    splitSpans: function (range, startContainer, endContainer) {
        var beforeSpan, afterSpan,
            startContainerParent = startContainer.parentNode,
            endContainerParent = endContainer.parentNode,
            util = OK.editor.util;

        if (util.isSpan(startContainerParent) && startContainer.previousSibling) {
            beforeSpan = util.inheritSpan(startContainerParent, null, null);
            util.moveAllBefore(startContainer, beforeSpan);
            if (!util.isEmpty(beforeSpan)) util.before(startContainerParent, beforeSpan); // Недопускаем: <s c6>​</s><s class="">​​</s><s c6>​2</s>1
        }
        if (util.isSpan(endContainerParent) && endContainer.nextSibling) {
            afterSpan = util.inheritSpan(endContainerParent, null, null);
            util.moveAllAfter(endContainer, afterSpan);
            if (!util.isEmpty(afterSpan)) util.after(endContainerParent, afterSpan); // Недопускаем: 1<s c6>​2</s><s class="">​​</s><s c6>​</s>
        }
    },

    hasSameStyle: function (element, cssStyle) {
        var split = cssStyle.split(":"),
            styleAttr = split[0],
            styleValue = split[1].trim(),
            currentStyleValue = $(element).css(styleAttr);

        if (styleAttr === OK.editor.DECORATION_STYLE_NAME) {
            currentStyleValue = currentStyleValue.split(" ")[0];
        }

        return currentStyleValue === styleValue;
    },

    /**
     * Форматирование места где стоит одиночный курсор (не диапазон)
     * @param range
     * @param startContainer
     * @param endContainer
     * @param cssStyle
     * @param cssSelected
     */
    formatSingle: function (range, startContainer, endContainer, cssStyle, cssSelected) {
        var span, util = OK.editor.util, newSpan, endNodeCase = util.isText(startContainer)
            && (util.isWebKit || util.isSafari ? startContainer.nodeValue : util.cleanZeroWidth(startContainer.nodeValue)).length === range.endOffset
            && (util.isSafari || util.isWebKit || startContainer.parentNode.lastChild == startContainer);
        if (util.isSpan(startContainer.parentNode)) {
            span = startContainer.parentNode;
        } else if (util.isSpan(startContainer)) {
            span = startContainer;
        }

        if (span && this.hasSameStyle(span, cssStyle) // Снимаем выбранное оформление текста по повторному клику
            && (!(endNodeCase) // Не надо убирать при этом фон у уже напечатанного текста (аналогично цвет) http://screencast.com/t/URQmrLgA28TI
            || util.isEmptyElement(span))) { // пустой <span>
            $(span).css(cssStyle.split(":")[0],"");
        } else {
            if (span) {
                if (util.isEmptyElement(span)) { // пустой <span>
                    util.formatCss(span, cssStyle);
                } else {
                    newSpan = util.inheritSpan(span, cssStyle, util.zeroWidthSpace); // наследуем предыдущее оформление
                    if (util.isText(endContainer)) {
                        if (range.endOffset < endContainer.nodeValue.length) {
                            this.splitTextNodes(range, startContainer, endContainer);
                        }
                    }
                    this.splitSpans(range, startContainer, endContainer);
                    if (endNodeCase && cssSelected) $(newSpan).css(cssStyle.split(":")[0],""); // уберем лишний класс в этом случае, если его "отжали"
                    util.after(startContainer.parentNode, newSpan);

                    util.moveFocus(newSpan);
                }
            } else {
                newSpan = util.createSpan(cssStyle, util.zeroWidthSpace);
                range.insertNode(newSpan);
                util.moveFocus(newSpan);
            }
        }
    }
};

